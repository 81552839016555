import React from 'react';
import SignupForm from '../components/sections/SignupForm';
import SectionHeader from '../components/sections/partials/SectionHeader';
import { Link } from 'react-router-dom';

class Resume extends React.Component {
  render() {
    return (
      <section>
        <div className="container">
          <div>
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <a href="/Michael_Oristian_Senior_Mobile_Software_Engineer.pdf">
                    <embed type="application/pdf" src="/Michael_Oristian_Senior_Mobile_Software_Engineer.pdf" width="425" height="550"></embed>
                  </a>
                  <br/>
                  <a href="/Michael_Oristian_Senior_Mobile_Software_Engineer.pdf" className="button button-primary button-wide-mobile button-sm">Download Resumé</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;