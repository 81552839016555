import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Experience with Humility',
      paragraph: 'I am a self-directed worker with an entrepreneurial DIY attitude, but I enjoys collaboration with others on projects.  I strive towards elegant code and solutions, but technology is always changing and there is so much to discover, learn and improve upon.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                    A career in technology consulting.
                  </h3>
                  <p className="m-0">
                    From Fortune 500 companies to Startups, I've added value as an engineer with strong communication skills
                    who can explain complex technologies to business stakeholders as well as translate and distill functional
                    requirements for the technical team.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image-01.png')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                    A successful exit as an entrepreneur
                  </h3>
                  <p className="m-0">
                    When my brother and I co-founded the free consumer app Lucyphone.com "Never Wait on Hold Again "&#8482;,
                    we didn't realize that we would ultimately find our market in enterprise virtual queuing, those services which 
                    promise to call you back when it's your turn.  Beyond my development and product management responsibilities,
                    I found myself an enterprise salesperson, calculating ROI for C-level executives and submitting RFP responses
                    in very long sales cycles.  Closing almost $1M in ARR, we were bought by the market leader in our space.  This is all
                    to illustrate that I understand how to pivot a product and how to wear many hats to move the product and company forward.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image-02.png')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                    A passion for the latest technologies
                  </h3>
                  <p className="m-0">
                    An early adopter at heart, I stay abreast of the latest trends in technology, especially web &amp; mobile development.
                    I'm always looking for ways to improve process, product and productivity.  The technology community has evolved some of the
                    best tools that we've ever known, and applying those capabilities to serve real business needs is a joy for me.  This can be
                    said for mature companies seeking growth as well as new companies creating new markets.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image-03.png')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;